<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col labor-planning-list-content__container fit-content">
        <div class="card fit-content border-0">
          <div class="d-flex justify-content-between mb-5">
            <h5 class="page-title mt-1 mb-0">
              Labor Planning
            </h5>
            <div class="d-flex justify-content-between w-auto">
              <router-link
                v-if="isSubmitBtnVisible"
                :to="{ name: 'labor-planning-view', params: { planId: 'new' } }"
                class="btn btn-sm btn-primary m-1"
              >
                Submit your Plan
              </router-link>
            </div>
          </div>
          <div class="card-body p-0 ag-grid-container grid-container">
            <ag-grid-vue
              :columnDefs="LaborPlaningOverviewTableColumnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="laborPlans"
              :suppressRowTransform="true"
              class="ag-theme-alpine h-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedDropdownMenuCellRenderer from '@/components/common/AgGreedDropdownMenuCellRenderer';
import { mapActions, mapGetters } from 'vuex';
import { getDiffDays, getISODateString, parseISOLocal, toUsDateFormat, toUsDateTimeFormat } from '@/lib/date';
import { planStatuses } from '@/components/LaborPlanning/constants';
import { userPrivileges } from '@/components/constants';
import { hasPrivilege } from '@/service/userAccess';

const cellClass = {
  [`${planStatuses.SUBMITTED}`]: 'text-primary',
  [`${planStatuses.RESUBMITTED}`]: 'text-primary',
  [`${planStatuses.APPROVED}`]: 'text-success',
  [`${planStatuses.REJECTED}`]: 'text-danger',
  [`${planStatuses.RESUBMISSION_ALLOWED}`]: 'text-warning'
};

export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedDropdownMenuCellRenderer,
  },
  computed: {
    ...mapGetters({
      laborPlans: 'laborPlanning/laborPlans'
    }),
    isSubmitBtnVisible() {
      return hasPrivilege(userPrivileges.submitLpPlan);
    },
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { display: 'flex', 'align-items': 'center' }
      };
    },
    LaborPlaningOverviewTableColumnDefs() {
      return [
        {
          headerName: 'Week',
          field: 'startDate',
          filter: true,
          sortable: true,
          valueFormatter:  (params) => {
            return toUsDateFormat(parseISOLocal(params.value));
          },
          width: 120
        },
        {
          headerName: 'Brand',
          sortable: true,
          filter: true,
          field: 'brandName',
          width: 220,
        },
        {
          headerName: 'Cost Commitment',
          field: 'commitmentCost',
          width: 150,
        },
        {
          headerName: 'Hrs Commitment',
          field: 'commitmentHours',
          width: 150,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 180,
          cellRenderer: (params) => {
            return `<span class="${cellClass[params.value] || ''}">${params.value}</span>`;
          }
        },
        {
          headerName: 'Submitted By',
          sortable: true,
          filter: true,
          field: 'createdBy.fullName',
          width: 180,
        },
        {
          headerName: 'Submitted At',
          field: 'createdAt',
          valueFormatter:  (params) => {
            return toUsDateTimeFormat(new Date(params.value));
          },
          width: 180,
        },
        {
          headerName: '',
          field: 'id',
          width: 80,
          cellStyle: {
            overflow: 'visible'
          },
          cellRenderer: 'AgGreedDropdownMenuCellRenderer',
          cellRendererParams: {
            items: [
              {
                label: 'View',
                isVisible: true,
                isDisabled: () => {
                  !hasPrivilege(userPrivileges.getLpPlan);
                },
                clickHandler: (rowData) => {
                  this.rowClickHandler(rowData.id);
                }
              },
              {
                label: 'Allow Resubmission',
                isVisible: hasPrivilege(userPrivileges.allowResubmitLpPlan),
                isDisabled: (rowData) => {
                  const isApproved = rowData.status === planStatuses.APPROVED;
                  const daysBeforeWeekStart = getDiffDays(rowData.startDate, getISODateString(new Date()));

                  const isResubmissionAllowed = isApproved && daysBeforeWeekStart > 2;
                  return !isResubmissionAllowed;
                },
                clickHandler: async (rowData) => {
                  await this.allowResubmitClickHandler(rowData.id);
                }
              }
            ]
          }
        }
      ];
    },
  },
  async mounted() {
    await this.fetchLaborPlans();
  },
  methods: {
    ...mapActions({
      allowPlanResubmit: 'laborPlanning/allowPlanResubmit',
      fetchLaborPlans: 'laborPlanning/fetchLaborPlans'
    }),
    rowClickHandler(planId) {
      this.$router.push({ name: 'labor-planning-view', params: { planId }});
    },
    async allowResubmitClickHandler(planId) {
      try {
        await this.allowPlanResubmit(planId);
        this.$toast.success('The plan was allowed to resubmit.');
        await this.$router.push({ name: 'labor-planning' });
      } catch (e) {
        this.$toast.error('An error occurred. Please try again or contact with an administrator.');
      }
    }
  }
};
</script>
<style>
.grid-container {
  height: 600px;
}

.page-title {
  font-family: HW-Cigars-Regular, serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
}
</style>

