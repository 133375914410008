<template>
  <div
    :id="`container-${id}`"
    ref="container"
    class="dropdown"
    @blur.capture="captureBlurEvent"
  >
    <button
      :id="id"
      ref="dropdown"
      class="btn"
      :class="calculatedClass"
      type="button"
      :disabled="disabled"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="dropdown.toggle()"
    >
      {{ label }}
    </button>
    <div
      class="dropdown-menu"
      :aria-labelledby="id"
      @click="clickHandler"
    >
      <slot />
    </div>
  </div>
</template>
<script>
import { Dropdown } from 'bootstrap';
import _ from 'lodash';

export default {
  props: [ 'variant', 'label', 'disabled', 'hideToggle', 'buttonStyles' ],
  data() {
    return {
      dropdown: null,
      id: _.uniqueId('dropdown-menu-button-')
    };
  },
  computed: {
    calculatedClass() {
      let result = [`btn-${this.variant || 'primary'}`];

      if (!this.hideToggle) {
        result.push('dropdown-toggle');
      }

      if (!_.isEmpty(this.buttonStyles)) {
        result = [ ...result, ...this.buttonStyles.split(' ') ];
      }

      return result;
    }
  },
  mounted() {
    this.dropdown = new Dropdown(this.$refs['dropdown']);
  },
  methods: {
    captureBlurEvent(e) {
      if (!this.$refs['container'].contains(e.relatedTarget) || !e.relatedTarget) {
        this.dropdown.hide();
      }
    },
    clickHandler() {
      this.dropdown.hide();
    }
  }
};
</script>
