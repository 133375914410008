<template>
  <Dropdown
    label="..."
    :hideToggle="true"
    class="small ag-table-action-button"
    buttonStyles="p-1"
  >
    <DropdownItem
      v-for="item in menuItems"
      :key="item.id"
      :class="{ disabled: item.isDisabled }"
      @click="item.clickHandler(rowData)"
    >
      {{ item.label }}
    </DropdownItem>
  </Dropdown>
</template>
<script>
import _ from 'lodash';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';


export default {
  components: {
    Dropdown,
    DropdownItem,
  },
  computed: {
    rowData() {
      return this.params.node.data;
    },
    menuItems() {
      const visibleItems = _.filter(this.params.items, [ 'isVisible', true ]);
      return _.map(
        visibleItems,
        (item) => {
          let isDisabled;
          if (typeof item.isDisabled === 'function') {
            isDisabled = item.isDisabled(this.rowData);
          } else {
            isDisabled = item.isDisabled || false;
          }
          return {
            ...item,
            isDisabled,
            id: _.uniqueId('ag-greed-dropdown-menu-item')
          };
        }
      );
    }
  }
};
</script>
<style>
.ag-table-action-button {
  line-height: 1;
}
</style>
